<template>
  <v-card>
    <v-toolbar color="color1 color1Text--text" :dark="color1IsDark">
      <v-toolbar-title>Bid Manager</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        :slot="$vuetify.breakpoint.smAndDown ? 'extension' : null"
        color="color3"
        append-icon="fas fa-search"
        label="Search"
        hide-details
        v-model="search"
        solo-inverted
        clearable
      ></v-text-field>
    </v-toolbar>
    <v-card-text>
      <v-row dense>
        <v-col cols="6" class="title">
          Bids Issued
        </v-col>
        <v-col cols="6" class="title text-right">
          <v-btn color="color3 color3Text--text" class="mr-1" fab small @click.stop="onAddClick">
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-btn color="color3 color3Text--text" class="mr-1" fab small @click.stop="load" :loading="loading">
            <v-icon>fas fa-sync-alt</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4" xl="3" v-for="(bid,i) in bids" :key="i">
          <v-card class="fill-height">
            <v-card-title>
              <v-img
                :src="bid.club.logoUrl"
                max-height="40"
                max-width="80"
                contain
                class="mr-2"
              ></v-img>
              {{bid.club.name}}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div class="black--text">Limits</div>
              <div v-for="l in bid.limits" :key="l.tournamentDivisionId">{{  l.statusString }}</div>
              <div class="text-right mt-n6">
                <v-btn color="color3" text small icon @click.stop="edit(bid)">
                  <v-icon>fas fa-pencil</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- CLUB ADD DIALOG -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar color="color1 color1Text--text">
          <v-window v-model="windowI">
            <v-window-item :key="0"><v-toolbar-title>Select A Club</v-toolbar-title></v-window-item>
            <v-window-item :key="1"><v-toolbar-title>{{ selectedClub && selectedClub.club.name }}</v-toolbar-title></v-window-item>
            <v-window-item :key="2"><v-toolbar-title>Delete {{ selectedClub && selectedClub.club.name }}?</v-toolbar-title></v-window-item>
          </v-window>
        </v-toolbar>
        <v-card-text class="py-4">
          <v-window v-model="windowI">
            <v-window-item :key="0">
              <ClubAutocomplete
                v-if="windowI === 0"
                label="Club"
                :allowAdd="true"
                :returnObject="true"
                @input="onAdd"
              ></ClubAutocomplete>
            </v-window-item>
            <v-window-item :key="1">
              <template v-if="selectedClub">
                <v-btn color="color3 color3Text--text" x-small text @click.stop="windowI--" v-if="selectedClub.id === 0">
                  <v-icon class="mr-3">fas fa-caret-left</v-icon>Choose a club
                </v-btn>
                <v-data-table
                  :headers="[
                    { text: 'Division', value: 'name', sortable: false },
                    { text: 'Limit', value: 'limit', sortable: false }
                  ]"
                  :items="selectedClub.limits.filter(f => f.display)"
                  hide-default-footer
                  :items-per-page="-1"
                  item-key="tournamentDivisionId"
                  :loading="loading"
                >
                  <template v-slot:[`item.limit`]="{ item }">
                    <v-text-field
                      v-model.number="item.limit"
                      dense filled
                      hide-details
                      type="number"
                    ></v-text-field>
                  </template>
                </v-data-table>
                <div class="text-end mt-3" v-if="selectedClub.id">
                  <v-btn
                    color="error"
                    small icon text
                    @click.stop="windowI++"
                  >
                    <v-icon>fas fa-trash</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-window-item>
            <v-window-item :key="2">
              <div v-if="selectedClub" class="text-center title">Are you sure you want to delete {{selectedClub.club.name}}?</div>
            </v-window-item>
          </v-window>
        </v-card-text>
        <v-window v-model="windowI">
          <v-window-item :key="0"></v-window-item>
          <v-window-item :key="1">
            <v-divider></v-divider>
              <v-card-actions class="justify-center">
                <v-btn color="success" @click.stop="save" :loading="loading">Save</v-btn>
                <v-btn color="error" @click.stop="dialog = false" :disabled="loading">cancel</v-btn>
              </v-card-actions>
          </v-window-item>
          <v-window-item :key="2">
            <v-divider></v-divider>
              <v-card-actions class="justify-center">
                <v-btn color="success" @click.stop="deleteMe" :loading="loading">Yes</v-btn>
                <v-btn color="error" @click.stop="windowI--" :loading="loading">No</v-btn>
              </v-card-actions>
          </v-window-item>
        </v-window>
      </v-card>
    </v-dialog>
  </v-card>

</template>

<script>
import { mapGetters } from 'vuex'
import ClubAutocomplete from '@/components/Clubs/ClubAutocomplete.vue'

export default {
  data () {
    return {
      loading: false,
      dialog: false,
      search: null,
      bids: [],
      windowI: 0,
      selectedClub: null
    }
  },
  computed: {
    ...mapGetters(['user', 'tournament', 'color1IsDark']),
    dto () {
      const c = this.selectedClub
      return {
        id: c.id,
        tournamentId: this.tournament.id,
        club: c.club,
        limits: c.limits.map(m => {
          const l = isNaN(+m.limit) ? 0 : +m.limit
          return {
            id: m.id,
            tournamentDivisionId: m.tournamentDivisionId,
            limit: l
          }
        }),
        deleted: c.deleted
      }
    }

  },
  methods: {
    load () {
      this.loading = true

      this.$VBL.club.getBids(this.tournament.id)
        .then(r => {
          this.bids = []
          r.data.forEach(dto => {
            this.bids.push(this.mapClubBid(dto))
          })
          this.window = 0
          this.dialog = false
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    save () {
      this.loading = true

      this.$VBL.club.postBid(this.tournament.id, this.dto)
        .then(r => {
          this.bids = []
          r.data.forEach(dto => {
            this.bids.push(this.mapClubBid(dto))
          })
          this.window = 0
          this.dialog = false
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    edit (bid) {
      bid = JSON.parse(JSON.stringify(bid))
      this.selectedClub = this.mapClubBid(bid)
      this.windowI = 1
      this.dialog = true
    },
    deleteMe () {
      if (this.selectedClub) {
        this.selectedClub.deleted = true
        this.save()
      }
    },
    onAddClick (v) {
      this.dialog = true
    },
    onAdd (v) {
      const existing = this.bids.find(f => f.club.id === v.id)
      if (existing) {
        return this.edit(existing)
      }
      if (v) {
        this.mapClubBid({
          id: 0,
          club: v,
          limits: []
        })
        this.windowI = 1
      }
    },
    mapClubBid (dto) {
      var newClub = {
        id: dto.id,
        tournamentId: this.tournament.id,
        club: dto.club,
        limits: [],
        deleted: false
      }

      for (const d of this.tournament.divisions) {
        const existing = dto ? dto.limits.find(f => f.tournamentDivisionId === d.id) : null
        newClub.limits.push({
          id: existing?.id || 0,
          tournamentDivisionId: d.id,
          name: d.name,
          display: d.isPublic,
          limit: existing?.limit || 0,
          used: existing?.used || 0,
          get statusString () {
            return `${this.name} (${this.used}/${this.limit})`
          }
        })
      }

      this.selectedClub = newClub

      return newClub
    }
  },
  watch: {
    dialog: function (v) {
      if (!v) {
        this.windowI = 0
        this.selectedClub = null
      }
    }
  },
  mounted () {
    this.load()
  },
  components: {
    ClubAutocomplete
  }
}
</script>
